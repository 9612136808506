
import { useEffect, useState } from 'react';

import axios from 'axios';

export const AxiosAuthGet = (url = '', token = '') => {
    const [apiResponse, setApiResponse] = useState(null);
    useEffect(() => {
        let mounted = true;
        axios.get(
            url,
            {
                headers: {
                    "Authorization": `Bearer ${token}`,
                },
            })
            .then(function (response) {
                if (mounted) {
                    // handle success
                    setApiResponse(response);
                }
            })
            .catch(function (error) {
                if (mounted) {
                    if(error.response && error.response.status === 401){
                        window.location = "#/401";
                    }else if(error.response && error.response.status === 404){
                        window.location = "#/404";
                    }else if(error.response && error.response.status >= 500){
                        window.location = "#/500";
                    }
                    // handle error
                    setApiResponse(error);
                }
            })
            .then(function () {
                // always executed
            });
        return function cleanup() {
            mounted = false;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return apiResponse;
}

export async function axiosGet(url = '', token = '') {
    let answer = null;
    // Make a request for a user with a given ID
    await axios.get(
        url,
        {
            headers: {
                "Authorization": `Bearer ${token}`,
                'content-type': 'application/x-www-form-urlencoded'
            },
        })
        .then(function (response) {
            // handle success
            answer = response;
        })
        .catch(function (error) {
            if(error.response && error.response.status === 401){
                window.location = "#/401";
            }else if(error.response && error.response.status === 404){
                window.location = "#/404";
            }else if(error.response && error.response.status >= 500){
                window.location = "#/500";
            }
            // handle error
            answer = error;
        })
        .then(function () {
            // always executed
        });
    return answer;
}

export async function axiosAuthGetNoState(url = '', token = '') {
    let answer = null;
    // Make a request for a user with a given ID
    await axios.get(
        url,
        {
            headers: {
                "Authorization": `Bearer ${token}`,
                'content-type': 'application/x-www-form-urlencoded'
            },
        })
        .then(function (response) {
            // handle success
            answer = response;
        })
        .catch(function (error) {
            if(error.response && error.response.status === 401){
                window.location = "#/401";
            }else if(error.response && error.response.status === 404){
                window.location = "#/404";
            }else if(error.response && error.response.status >= 500){
                window.location = "#/500";
            }
            // handle error
            answer = error;
        })
        .then(function () {
            // always executed
        });
    return answer;
}

export async function axiosAuthPost(url = '', params = {}, token = '', convertir = true) {
    let answer = null;
    let bodyFormData = new FormData();
    Object.keys(params).forEach(key =>
        bodyFormData.append(key, (convertir === true) ? JSON.stringify(params[key]) : params[key])
    );
    // Make a request for a user with a given ID
    await axios.post(
        url,
        bodyFormData,
        {
            headers: {
                "Authorization": `Bearer ${token}`,
                'content-type': 'application/x-www-form-urlencoded'
            },
        })
        .then(function (response) {
            // handle success
            answer = response;
        })
        .catch(function (error) {
            if(error.response && error.response.status === 401){
                window.location = "#/401";
            }else if(error.response && error.response.status === 404){
                window.location = "#/404";
            }else if(error.response && error.response.status >= 500){
                window.location = "#/500";
            }
            // handle error
            answer = error;
        })
        .then(function () {
            // always executed
        });
    return answer;
}

export async function axiosAuthPostWithFiles(url = '', params = {}, token = '') {
    let bodyFormData = new FormData();

    Object.keys(params).forEach(key =>
        bodyFormData.append(key, params[key])
    );

    let answer = null;
    // Make a request for a user with a given ID
    await axios.post(
        url,
        bodyFormData,
        {
            headers: {
                "Authorization": `Bearer ${token}`,
                'content-type': `multipart/form-data; boundary=${bodyFormData._boundary}`,
            },
        })
        .then(function (response) {
            // handle success
            answer = response;
        })
        .catch(function (error) {
            if(error.response && error.response.status === 401){
                window.location = "#/401";
            }else if(error.response && error.response.status === 404){
                window.location = "#/404";
            }else if(error.response && error.response.status >= 500){
                window.location = "#/500";
            }
            // handle error
            answer = error.response;
        })
        .then(function () {
            // always executed
        });
    return answer;
}

export async function axiosAuthPostWithMultiFiles(url = '', params = {}, files = [], token = '') {
    let bodyFormData = new FormData();
    Object.keys(params).forEach(key =>{
        if(Array.isArray(params[key])){
            let c = 0
            Object.keys(params[key]).forEach(arraykey =>{   
                bodyFormData.append(`${key}[${c}]`,  JSON.stringify(params[key][arraykey]))
                c++
            })
        }else{
            bodyFormData.append(key, params[key])
        }
    })
    Object.keys(files).forEach(key =>{
        if(Array.isArray(files[key])){
            let i = 0
            Object.keys(files[key]).forEach(arraykey =>{   
                //console.log(files[key][arraykey])
                //bodyFormData.append(key, {'size':files[key][arraykey].size,'name':files[key][arraykey].name,'type':files[key][arraykey].type})
                bodyFormData.append(`${key}[${i}]`, files[key][arraykey])
                i++
            })
        }
        //bodyFormData.append(key,  files[key])
    })

    let answer = null;
    // Make a request for a user with a given ID
    await axios.post(
        url,
        bodyFormData,
        {
            headers: {
                "Authorization": `Bearer ${token}`,
                'content-type': `multipart/form-data; boundary=${bodyFormData._boundary}`,
            },
        })
        .then(function (response) {
            // handle success
            answer = response;
        })
        .catch(function (error) {
            if(error.response && error.response.status === 401){
                window.location = "#/401";
            }else if(error.response && error.response.status === 404){
                window.location = "#/404";
            }else if(error.response && error.response.status >= 500){
                window.location = "#/500";
            }
            // handle error
            answer = error.response;
        })
        .then(function () {
            // always executed
        });
    return answer;
}
/*export async function AxiosAuthGet(url = '', token = ''){
    let answer=null;
    // Make a request for a user with a given ID
    await axios.get(
        url,
        { headers: {
            "Authorization" : `Bearer ${token}`,
            },
        })
        .then(function (response) {
            // handle success
            answer=response;
        })
        .catch(function (error) {
            // handle error
            answer=error;
        })
        .then(function () {
            // always executed
    });

    return answer;
}*/

export async function AxiosAuthConsultarTCSAP(url) {
    var tcs = null;
    await axios.post(url,
        {
            mode: 'cors',
            headers:
            {
                'Content-Type': 'text/xml',
                'Authorization': 'Basic dGkwMTpuUGNpMlM0UA==',
            },
            timeout: 5000
        }).then(res => {
            // if(res.data.data.includes("Error en puente")){
            //     tcs = res.data.data;
            // }else{
                tcs = res?.data
                //console.log(res);
            // }
        }).catch(function(error){ 
            if(error.response && error.response.status === 401){
                window.location = "#/401";
            }else if(error.response && error.response.status === 404){
                window.location = "#/404";
            }else if(error.response && error.response.status >= 500){
                window.location = "#/500";
            }
            console.log(error) 
        });
    return tcs;
}

export async function axiosAuthPut(url = '', params = {}, token = '') {
    let answer = null;
    
    await axios.put(
        url,
        params,
        {
            headers: {
                "Authorization": `Bearer ${token}`,
            },
        })
        .then(function (response) {
            // handle success
            answer = response;
        })
        .catch(function (error) {
            if(error.response && error.response.status === 401){
                window.location = "#/401";
            }else if(error.response && error.response.status === 404){
                window.location = "#/404";
            }else if(error.response && error.response.status >= 500){
                window.location = "#/500";
            }
            // handle error
            answer = error;
        })
        .then(function () {
            // always executed
        });
    return answer;
}

export async function axiosAuthDelete(url = '', params = {}, token = '') {
    let answer = null;

    await axios.delete ( url, {
            data: params,
            headers: {
                "Authorization": `Bearer ${token}`,
            },
        })
        .then(function (response) {
            // handle success
            answer = response;
        })
        .catch(function (error) {
            if(error.response && error.response.status === 401){
                window.location = "#/401";
            }else if(error.response && error.response.status === 404){
                window.location = "#/404";
            }else if(error.response && error.response.status >= 500){
                window.location = "#/500";
            }
            // handle error
            answer = error;
        })
        .then(function () {
            // always executed
        });
    return answer;
}

