import React from 'react'
import { Outlet, Navigate } from 'react-router-dom'
import PropTypes from 'prop-types';

export const NewPrivateRoutes = ({isAuthenticated}) => {
    //let auth = {'token':false}
    if(window.location.hash !== '/' && isAuthenticated){
        localStorage.setItem('lastPath',window.location.hash);
    }else if(
        window.location.hash.includes('garantias/inicio') 
        || window.location.hash == '#/garantias/' 
        || window.location.hash.includes('garantias/consultas/consultargarantias') 
        || window.location.hash.includes('garantias/registros/registrarretroalimentacion')
    ){
        localStorage.setItem('lastPath',window.location.hash);  
        return(
            <Outlet/>
        )
    }
    //si algo falla con las rutas borrar o comentar este IF
    if((window.location.hash !== '/' && (
        window.location.hash.includes('garantias/moderacion') ||
        window.location.hash.includes('garantias/calendario')))
    ){
        localStorage.setItem('lastPath',window.location.hash);
    }
    return(
        isAuthenticated ? <Outlet/> : <Navigate to="/login"/>
    )
}

NewPrivateRoutes.propTypes = {
    isAuthenticated: PropTypes.bool.isRequired,
}

