import { types } from "../types/types";

export const authReducer = (state = {isLoggedIn:false}, action) => {
    switch(action.type){
        case types.login:
            return {
                isLoggedIn:true,
                user:action.payload.user,
                token:action.payload.token,
                avatar:action.payload.avatar,
                email:action.payload.email,
                uenId:action.payload.uenId,
                sociedadCO:action.payload.sociedadCO,
                roles:action.payload.roles,
            }
        case types.logout:
            return {
                isLoggedIn:false,
                user:null,
                token:null,
                avatar:null,
                email:null,
                uenId:null,
                sociedadCO:null,
                roles:null
            }
        default:
            return state;
    }
}
