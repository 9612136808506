import { types } from "../types/types";

const login = (user, token, avatar, email, uenId, sociedadCO, roles) => ({
    type:types.login,
    payload: {
        user, 
        token, 
        avatar,
        email,
        uenId,
        sociedadCO,
        roles
    }
})

const logout = () => ({
    type:types.logout
})

export const startGoogleLogin = (user, access_token, avatar, email, uenId, sociedadCO, roles) => {
    return (dispatch) => {
        dispatch(login(user, access_token, avatar, email, uenId, sociedadCO, roles));
    }
}

export const startLogout = () => {
    return (dispatch) => {
        dispatch(logout());
    }
}
