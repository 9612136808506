export const types = {
    login: '[Auth] Login',
    logout: '[Auth] Logout',
    uiSetError: '[UI] Set Error Msg',
    uiRemoveError: '[UI] Remove Error Msg',
    uiStartLoading: '[UI] Start Loading',
    uiFinishLoading: '[UI] Finish Loading',
    uiParameters: '[UI] Parameters',
    uiSetFilters: '[UI] Set Custom Filters',
    uiSetUserFilters: '[UI] Set User Filters',
    uiSetFacturasFilters: '[UI] Set Factura Filters',
    uiSetNDFilters: '[UI] Set No Deducibles Filters',
    uiSetReposFilters: '[UI] Set Reposiciones Filters',
}