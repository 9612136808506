import { createStore, combineReducers, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { authReducer } from './redux/reducers/authReducer';
import { uiReducer } from './redux/reducers/uiReducer';

const initialState = {
  sidebarShow: true,
  asideShow: false,
  theme: 'default',
}

const changeState = (state = initialState, { type, ...rest }) => {
  switch (type) {
    case 'set':
      return { ...state, ...rest }
    default:
      return state
  }
}

const composeEnhancers = (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

// Aqui deben ir todos los reducer
const rootReducer = combineReducers({
  coreUi:changeState,
  auth:authReducer,
  ui:uiReducer,
});

// Debemos remover las devtools despues
const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware( thunk ))
  );
//const store = createStore(changeState)

export default store
