import React from 'react';
import 'src/scss/style.scss';
import 'spinkit/spinkit.min.css';
import {
    CCol,
    CSpinner
} from '@coreui/react-pro'
export const LoadingSpinner = ({type = '', color=''}) => {


    return (
        <div className="d-flex justify-content-center">
            {
                (type === 'wave') ?
                    <div className="sk-wave">
                        <div className="sk-wave-rect"></div>
                        <div className="sk-wave-rect"></div>
                        <div className="sk-wave-rect"></div>
                        <div className="sk-wave-rect"></div>
                        <div className="sk-wave-rect"></div>
                    </div>
                : (type === 'doublebounce') ?
                    <div className="sk-bounce">
                        <div className="sk-bounce-dot"></div>
                        <div className="sk-bounce-dot"></div>
                    </div>
                : (type === 'chase') ?
                    <div className="sk-chase">
                        <div className="sk-chase-dot"></div>
                        <div className="sk-chase-dot"></div>
                        <div className="sk-chase-dot"></div>
                        <div className="sk-chase-dot"></div>
                        <div className="sk-chase-dot"></div>
                        <div className="sk-chase-dot"></div>
                    </div>
                : (type === 'swing') ?
                    <div className="sk-swing">
                        <div className="sk-swing-dot"></div>
                        <div className="sk-swing-dot"></div>
                    </div>
                : (type === 'fading') ?
                    <div className="sk-circle-fade ">
                        <div className="sk-circle-fade-dot"></div>
                        <div className="sk-circle-fade-dot"></div>
                        <div className="sk-circle-fade-dot"></div>
                        <div className="sk-circle-fade-dot"></div>
                        <div className="sk-circle-fade-dot"></div>
                        <div className="sk-circle-fade-dot"></div>
                        <div className="sk-circle-fade-dot"></div>
                        <div className="sk-circle-fade-dot"></div>
                        <div className="sk-circle-fade-dot"></div>
                        <div className="sk-circle-fade-dot"></div>
                        <div className="sk-circle-fade-dot"></div>
                        <div className="sk-circle-fade-dot"></div>
                    </div>
                : (type === 'exe') ?
                    <div className="image-container"></div>
                :
                <CSpinner color={color}/>
            }
        </div>
    )
}
