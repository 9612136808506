import React, { useEffect, useContext, Suspense } from 'react';
import {HashRouter, Route, Routes, Navigate} from 'react-router-dom';
import { NewPrivateRoutes } from './NewPrivateRoutes';
import { useSelector, useDispatch } from 'react-redux';
import { startGoogleLogin } from 'src/redux/actions/auth';
import { axiosAuthGetNoState } from 'src/helpers/axios/AxiosRequest';
import { GlobalContext } from 'src/context/GlobalContext';
import { LoadingSpinner } from 'src/helpers/ui/LoadingSpinner';
import '../scss/style.scss';

const loading = (<LoadingSpinner />);

// Pages
const DefaultLayout         = React.lazy(() => import('../layout/DefaultLayout'))
const Login                 = React.lazy(() => import('../views/pages/login/Login'))
const Page404               = React.lazy(() => import('../views/pages/page404/Page404'))
const Page401               = React.lazy(() => import('../views/pages/page401/Page401'));
const Page500               = React.lazy(() => import('../views/pages/page500/Page500'))
const GoogleLogin           = React.lazy(() => import('../views/pages/login/GoogleLogin'));
const AutorizarPpto         = React.lazy(() => import('../views/pages/abierto/AutorizarPpto'));

// Modulos
const GarantiasApp             = React.lazy(() => import('../views/garantias/GarantiasApp'));
const ConfiguracionTIApp       = React.lazy(() => import('../views/configuracionti/ConfiguracionTIApp'));
const AboutApp                 = React.lazy(() => import('../views/about/AboutApp'));

export const AppRouter = () => {
    const {isLoggedIn} = useSelector(state => state.auth);
    const isLoggedInApp = ((localStorage.getItem('isLoggedIn') == 'true') ? true : false) || false; // NO SIRVEEE AAAAA
    const lastPath = localStorage.getItem('lastPath') || '#';
    const dispatch = useDispatch();
    const {BACKEND_URL} = useContext(GlobalContext);
    useEffect(() => {
        // Check in store state
        if(isLoggedIn === false){
            // Page Reload? Check on LocalStorage
            const localSession = localStorage.getItem('isLoggedIn') || false;
            const token = localStorage.getItem('token') || '';
            
            if(localSession && token !== ''){
                //Obtenemos información adicional del usuario   
                const url= `${BACKEND_URL}/administracion/user`;
                let request = axiosAuthGetNoState(url, token);
                request.then(function(data){
                    //console.log(data);
                    //Vamos por roles
                    const urlRoles= `${BACKEND_URL}/administracion/users/myRoles/${data.data.data.id}`;
                    let requestRoles = axiosAuthGetNoState(urlRoles, token);
                    requestRoles.then(function(dataRoles){
                        //console.log(dataRoles);
                        //Guardamos todo
                        dispatch(startGoogleLogin(data.data.data.name, token, data.data.data.avatar, data.data.data.email, data.data.data.uen_id, data.data.data.uen?.clave_co, dataRoles.data.data.roles));
                    });
                });
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoggedIn]);
    
    return (
        <div>
            <HashRouter>
                <Suspense fallback={loading}>   
                    <Routes>
                        { /* Publicas */}
                        <Route path="*" name="Inicio" element={<DefaultLayout />} />
                        <Route index element={<Navigate to="/inicio" />} />
                        {/* <Route path="/garantias/*" name="Garantías" element={<GarantiasApp />} /> */}
                        <Route exact path="/404" name="Page 404" element={<Page404 />} />
                        <Route exact path="/500" name="Page 500" element={<Page500 />} />
                        <Route exact path="/401" name="No autorizado" element={<Page401 />} />
                        <Route exact path="/login" name="Login Page" element={<Login />} lastPath={lastPath} isAuthenticated={isLoggedIn} />
                        <Route path='/auth/google' element={<GoogleLogin />}/>
                        <Route path="/autorizarppto/:id" name="Espacio Abierto" element={<AutorizarPpto />} />
                        { /* Privadas */}
                        <Route element={<NewPrivateRoutes isAuthenticated={isLoggedIn} />}>
                            <Route path="/about/*" name="About" element={<AboutApp />} />
                            <Route path="/configuracionti/*" name="Configuración TI" element={<ConfiguracionTIApp />} />
                            <Route path="/garantias/*" name="Garantías" element={<GarantiasApp />} />
                            {/* no poner nada debajo de esta ruta TODO ARRIBA  */}
                        </Route>
                    </Routes>
                </Suspense>
            </HashRouter>
        </div>
    )
}